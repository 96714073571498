<template>
  <div class="Profile_Parent panel Administration_panel">
    <div class="inner_panel">
      <div
        style="display: flex; justify-content: flex-start; align-items: center"
      >
        <div class="protectedStatus">
          <img @load="imgLoaded" src="../assets/media/protected.svg" alt="" />
          {{ getBeautifiedRole() }}
        </div>
      </div>
      <h4 style="margin-bottom: 0.5rem">Departamente</h4>
      <div
        :class="0 ? 'Administration_section_loading' : ''"
        class="Administration_panel_section"
      >
        <div class="Administration_panel_block">
          <h5 v-if="showTotalRecords">Total departamente: {{ totalRecords }}</h5>
          <div class="form-flex">
            <div class="form-group noMB">
              <b class="asterisk">Denumirea departamentului</b>
              <input
                v-model="models.name"
                type="text"
                class="vInput"
              />
            </div>
            <div class="form-group">
              <b class="asterisk">Email-ul departamentului</b>
              <input
                v-model="models.email"
                type="text"
                class="vInput"
              />
            </div>
          </div>
          <div v-if="isSuperadmin" class="form-flex">
            <div class="form-group">
              <b class="asterisk">Instituția departamentului</b>
              <v-select 
                :options="institutionaData"
                label="name"
                v-model="models.institution"
              >
                <template #option="{ name }">
                  <span class="v-select_li_custom">
                    <span>{{name}}</span>
                    <i v-tooltip="name" class="fas fa-info-circle"></i>
                  </span>
                </template>
              </v-select>
            </div>
          </div>
          <div class="right">
            <div @click="saveData()" class="Button1">
              <div class="icon">
                <img
                  @load="imgLoaded"
                  src="../assets/media/for_table/circle_ADD_new_etap.svg"
                  alt=""
                />
              </div>
              <span class="Btn_content">
                {{ btnText }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DEPARTMENT_ADD, DEPARTMENT_MODIFY, INSTITUTIONS_PAGINATED } from '@/api.js'
import updateRoleStatus from '../mixins/updateUserRoleStatus.js'

const defaultData = () => ({
  name: '',
  email: '',
  institution: null
})
const checker = window['validator'] || (() => false)
const safeApi = window['apiTimeout'](1000, false, true)

export default {
  mixins: [updateRoleStatus],
  props: {
    options: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      models: defaultData(),
      editId: null,
      institutionaData: []
    }
  },
  methods: {
    closePanel() {
      this.$emit('closePanel')
    },
    getInitialEditingData() {
      const x = this.options.data
      
      return {
        ...x,
        institution: x?.institution?.id ?? null
      }
    },
    getModelsData() {
      const excludes = []
      const models = {...this.models}

      excludes.forEach(e => delete models[e])

      if(this.isSuperadmin) {
        models.institution = models.institution?.id ?? null
      }

      return models
    },
    isValid(useAlerts) {
      const x = checker.call(this.models, [], ['institution'])
      const w = this.validateEmail(this.models.email)
      const q = x && w && this.isEdited(this.getInitialEditingData(), this.getModelsData())

      const alert = msg => this.$toastr.w(msg || 'Completați toate câmpurile obligatorii.')

      if(useAlerts) {
        if(!x) alert()
        else if(!w) alert('Introduceți un email corect.')
        else if(this.action == 'edit' && !q) alert('Nu s-au găsit modificări.')
      }

      return w && (this.action == 'edit' ? q : x)
    },
    createDepartment() {
      const sendData = this.getModelsData()

      const error = msg => {
        this.$toastr.e(msg || 'Crearea departamentului a eșuat. Încercați mai târziu.')
        this.setLoad()
      }
      const success = () => {
        this.$toastr.s('Departamentul a fost creată.')
        this.closePanel()
        this.setLoad()
      }

      this.setSafeLoad(12000)
      
      DEPARTMENT_ADD(sendData).then(res => {
        if(this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
          success()
        } else {
          error()
        }
      }).catch(error)
    },
    editDepartment() {
      const sendData = this.getEdited(this.getInitialEditingData(), this.getModelsData())

      if(!this.isObjEmpty(sendData)) {
        const success = msg => {
          this.$toastr.s(msg || 'Departamentul a fost editat.')
          this.closePanel()
          this.setLoad()
        }
        const error = msg => {
          this.$toastr.e(msg || 'Editarea departamentului a eșuat. Încercați mai târziu.')
          this.setLoad()
        }

        this.setSafeLoad(12000)

        DEPARTMENT_MODIFY(this.editId, sendData).then(res => {
          if(this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            success()
          } else {
            error()
          }
        }).catch(error)
      }
    },
    saveData() {
      if(this.isValid(true)) {
        const fnMap = {
          'create': 'createDepartment',
          'edit': 'editDepartment'
        }
        const findFn = fnMap[this.action]

        if(findFn && typeof this[findFn] === 'function') {
          safeApi(this[findFn])
        }
      }
    },
    syncInstitutions() {
      const warn = (msg, isWarning) => {
        if(isWarning) {
          this.$toastr.w(msg || 'Nu sunt instituții disponibile de selectat.')
        } else {
          this.$toastr.e(msg || 'Datele instituțiilor nu au fost primite.')
          this.closePanel()
        }
      }

      INSTITUTIONS_PAGINATED(1, 1000).then(res => {
        if(Array.isArray(res?.data?.result)) {
          res = res.data.result

          if(res.length) {
            this.institutionaData = res.map(e => ({
              ...e,
              id: e.id ?? this.makeid(8),
              name: e.name ?? '-'
            }))
          } else {
            warn(false, true)
          }
        } else {
          warn()
        }
      }).catch(warn)
    },
    readAndSyncData(data) {
      if(!this.isObject(data)) return

      const x = this.models
      data = {...data}

      Object.keys(x).forEach(key => {
        if(data.hasOwnProperty(key)) 
          x[key] = data[key]
      })

      return {...x}
    }
  },
  computed: {
    action() {
      return this.options.action || 'create'
    },
    btnText() {
      return ({
        'create': 'Creează',
        'edit': 'Modifică'
      })[this.action] || 'Salvează'
    },
    showTotalRecords() {
      return Number.isInteger(this.totalRecords)
    },
    totalRecords() {
      return this.options.totalRecords ?? null
    }
  },
  created() {
    this.updateRoleStatus()

    this.$nextTick(() => {
      if(this.isSuperadmin) {
        this.syncInstitutions()
      } else {
        const userInstitution = this.getUserInstitution(true)
        this.models.institution = userInstitution?.id ?? null      
      }
    })
    if(this.action == 'edit') {
      const data = this.options.data

      if(this.isObject(data)) {
        this.editId = this.options.data?.id ?? null
        this.options.data = this.readAndSyncData(data)
      }
    }
  }
};
</script>

<style src="../assets/profile.css"></style>
<style src="../assets/administration.css"></style>