import { render, staticRenderFns } from "./DepartmentsPanel.vue?vue&type=template&id=f9d5a506&"
import script from "./DepartmentsPanel.vue?vue&type=script&lang=js&"
export * from "./DepartmentsPanel.vue?vue&type=script&lang=js&"
import style0 from "../assets/profile.css?vue&type=style&index=0&lang=css&"
import style1 from "../assets/administration.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports