<template>
  <div style="padding-left: 1.75rem">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      {{ stateLabels[0] }}
      <div class="subHeadTitle">{{ stateLabels[1] }}</div>
      <div class="right" v-if="PERMISIONS.create">
        <div class="right" style="margin-top: 1.2rem">
          <div v-if="showPanel" @click="closePanel()" class="goBack">
            <img src="../assets/media/vectors/arrow_back.svg" alt="">
          </div>
          <div v-else-if="PERMISIONS.create" @click="createDepartment()" class="Button1">
            <div class="icon">
              <img @load="imgLoaded" src="../assets/media/for_table/circle_ADD_new_etap.svg" alt="">
            </div>
            <span class="Btn_content">
              Adaugă departament
            </span>
          </div>
        </div>
      </div>
    </div>
    <Panel v-if="showPanel" :options="panelData" @closePanel="closePanel" />
    <template v-else>
      <template v-if="PERMISIONS.list">
        <table-parent
          :key="forceReload"
          :reloadCount="reloadTable"
          :tableHead="tableHead"
          :prepareFn="prepareData"
          :apiModule="tableModule"
          :noReload="true"
          :apiModuleName="'DEPARTMENTS_PAGINATED'"
          :tableMinimizeHead="true"
          :tableSpread="true"
          @Btn2="editDepartment"
          @Btn3="deleteDepartment"
          @totalRecords="x => totalRecords = x"
          @reload="syncInstitutionQuery(true)"
        />
      </template>
      <template v-else>
        <h3 style="display: flex; flex-direction: column; align-items: center">
          <span style="font-size: 4rem">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          Acces interzis
        </h3>
      </template>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from '@/mixins/departmentsPermMixin.js'
import updateRoleStatus from '../mixins/updateUserRoleStatus.js'
import { DEPARTMENTS_PAGINATED, DEPARTMENT_DELETE } from "../api.js";

import TableParent from '@/components/TableParent'
import Panel from '@/components/DepartmentsPanel'
import DialogModal from '@/components/DialogModal'

export default {
  name: 'Departments',
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  mixins: [PermisionsMixin, updateRoleStatus],
  components: {
    TableParent, Panel
  },
  data() {
    return {
      tableHead: [
        {title:'Denumire',sort:true,
        queryKey: 'name',
        bottomHead: {
            quikFiltre: true
        }},
        {title:'Email',
        queryKey: 'email',
        bottomHead: {
            quikFiltre: true
        }},
        {title:'Instituția',
        queryKey: 'institution.name',
        defaultValue: this.$route.query.institution || '',
        bottomHead: {
            quikFiltre: true
        }}
      ],
      tableModule: DEPARTMENTS_PAGINATED,
      panelData: false,
      totalRecords: 0,
      reloadTable: 0,
      forceReload: 0
    }
  },
  watch: {
    $route() {
      this.syncInstitutionQuery()
    },
    showPanel(x) {
      if(x) {
        this.$nextTick(() => this.syncInstitutionQuery(true))
      }
    }
  },
  methods: {
    reload() {
      this.forceReload++
    },
    syncInstitutionQuery(clear) {
      const x = this.tableHead[2]
      const query = this.$route.query.institution || ''

      if(clear && this.$route.fullPath != this.$route.path) this.$router.push(this.$route.path)

      if(this.isString(x?.defaultValue)) {
        x.defaultValue = clear ? '' : query

        this.forceReload++
      }
    },
    prepareData(row) {
      const preparation = [
        row.name || '-',row.email || '-',row.institution?.name || '-'
      ]

      const uuid = row.createdByUser
      const x = this.PERMISIONS
      const prepareBtn = []

      if(x.edit === true || this.checkOwner(x.edit, uuid)) {
        prepareBtn.push(2)
      }
      if(x.delete === true || this.checkOwner(x.delete, uuid)) {
        prepareBtn.push(3)
      }
      if(prepareBtn.length) {
        preparation.push([prepareBtn])
      }
      
      return preparation
    },
    closePanel() {
      this.panelData = false
    },
    openModal(options) {
      if(this.isObject(options)) {
        this.simulateLoad(() => this.panelData = options)
      }
    },
    createDepartment() {
      this.openModal({
        action: 'create',
        totalRecords: this.totalRecords
      })
    },
    editDepartment(data) {
      if(!this.isObject(data)) return

      this.openModal({
        action: 'edit',
        data
      })
    },
    deleteDepartment(data) {
      if(!Number.isInteger(data?.id)) return

      this.$modal.show(
        DialogModal,
        {
          target: 'DepartmentDelete',
          title: 'Ștergere departament',
          content: '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: 500;font-size: 2.2rem;">Ești sigur că vrei să ștergi departamentul selectat?</p></span>',
          closeBtnText: 'Anulează',
          button: {
            type: 3,
            value: 'Șterge',
            handler: () => {
              const error = msg => {
                this.$toastr.e(msg || 'Ștergerea departamentului a eșuat.')
                this.reload()
                this.setLoad()
              }

              this.setSafeLoad(12000)
              DEPARTMENT_DELETE(data.id).then(res => {
                if(res?.statusCode == 402) {
                  this.$toastr.w('Departamentul nu poate fi șters din cauza rolurilor de utilizator atașate de acesta.')
                  this.setLoad()
                  return
                }
                if(this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                  this.$toastr.s('Departamentul a fost ștears.')
                  this.reload()
                  this.setLoad()
                } else {
                  error()
                }
              }).catch(error)
            }
          }
        },
        {
          name: 'DepartmentDelete',
          adaptive: true,
          width: '650',
          height: '400'
        }
      )
    },
  },
  computed: {
    showPanel() {
      return !! this.panelData
    },
    stateLabels() {
      return this.showPanel ? [
        ' / Administrare',
        'Panou de Administrare'
      ] : ['', this.$route.name]
    }
  },
  created() {
    if(!this.PERMISIONS.list) {
      return
    }
    
    const canViewButtonsColumn = ['edit','delete']

    this.updateRoleStatus()
    if(this.checkParamsInObj(this.PERMISIONS, canViewButtonsColumn)) {
      this.tableHead.push({spacer:true,minimize:1,fixed:{
        right: true
      },bottomHead: {
          quikFiltre: 'clear'
      }})
    }
  }
}
</script>